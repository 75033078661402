import React, { useState, useEffect } from "react";
import TextFinal from "../textFinal";
import RecolectDataStart from "../RecolectDataStart/RecolectDataStart";
import sunIcon from "./../../assets/sun.png";
import idIcon from "./../../assets/id.png";
import "./IntroPage.scss";
import {
  infoMessage,
  successMessage,
} from "../utils/ErrorHandler/errorHandler";
import { Container, Row, Col } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import useQuery from "../../hooks/useQuery";
import Loader from "../utils/Loader/Loader";
function IntroPage(props) {
  const [index, setIndex] = useState(0);
  // const [loader, setLoader] = useState(false)
  console.log("props", props);
  const query = useQuery();
  const UrlCode = query.get("code");
  const history = useHistory();

  function next() {
    setIndex((index) => (index < 1 ? index + 1 : index));
  }

  useEffect(() => {
    console.log("useEffect>>>" + index);
    if (index !== 1) {
      const interval = setInterval(() => {
        setIndex((index) => (index < 1 ? index + 1 : index));
        props.setshowLoader(true);
      }, 5000);
      return () => {
        clearInterval(interval);
      };
    } else {
      loadAll();
    }
  }, [index]);

  async function loadAll() {
    // props.setshowLoader(true);
    props.api.spoolUp();
    await props.conexion();

    history.push(`/recolect?code=${UrlCode}`);
    props.setColorB(1);
    console.log("pushed route...");
  }

  return (
    <>
      {props.showLoader ? (
        <Loader txts={["Vamos a comenzar"]} />
      ) : (
        <div
          className="App content-text-next introPage"
          style={{ color: "black" }}
        >
          <div className="row">
            <div className="col-12">
              {/* -----------------------------INTRO MESSAGE 1---------------------------------------*/}
              {index === 0 && (
                <div
                  className="col-12 first_text"
                  style={{
                    padding: index !== 2 ? "20%" : "12%",

                    color: "white",
                    textAlign: "center",
                  }}
                >
                  <TextFinal type="h1" txt={"¡ Hola !"} />
                </div>
              )}

              {/* -----------------------------INTRO MESSAGE 3 WITH BUTTON---------------------------------------*/}
              {/*    {index === 1 &&(
         
                <Container className="third_text">
                  <Row>
                    <Col className="col1">
              
                      <TextFinal type="h1" txt={"Es momento"} />
                      <TextFinal type="h1" txt={"de conocerte"} />
                      <TextFinal type="h1" txt={"un poco mas"} />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col2">
     
                      <TextFinal type="h1" txt={"Vamos"} />
                      <TextFinal type="h1" txt={"a validar"} />
                      <TextFinal type="h1" txt={"tu identidad"} />
                    </Col>
                  </Row>

            
                </Container>
              )} */}

              {/* -----------------------------START RECOLECT DATA---------------------------------------*/}
              {/* {index === 2 && (
            //  props.setColorB(2),
            // <RecolectDataStart
            //   nextPage={props.nextPage}
            //   api={props.api}
            //   setPhoneEmail={props.setPhoneEmail}
            //   dataRepo={props.dataRepo}
            // />
            
          )} */}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default IntroPage;
