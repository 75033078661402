import React, { useState, useEffect } from "react";
// import iconre from "../static/sms-icon.png";
import "../VerifyEmailPage/InstruccionGeo.scss";
import "./RecolectVerify2.scss";
import OtpInput from "react-otp-input";
import {
  infoMessage2,
  successMessage,
  commonError,
} from "../utils/ErrorHandler/errorHandler";
import { useTimer } from "react-timer-hook";
import { Container, Col } from "react-bootstrap";
import smsIcon from "./../../assets/smsIcon.png";
import { useHistory, Link } from "react-router-dom";
import useQuery from "../../hooks/useQuery";
import { addEvent } from "../api/Events/EventsApi";
import { Button1, Button } from "../utils/Buttons/Buttons";

function RecolectVerify2(props) {
  let history = useHistory();
  const [opt, setOpt] = useState("");
  const query = useQuery();
  const UrlCode = query.get("code");
  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({
    expiryTimestamp: 100000000,
    onExpire: () => console.warn("onExpire called"),
  });

  const handleChange = (opt2) => setOpt(opt2);

  async function verifyOtpMobile() {
    if (!opt || opt === "") {
      infoMessage2("El código es incorrecto");
      return;
    }
    const api = props.api;
    var result = await api.otpMobile(opt);
    console.log(result, "thiss opt");
    if (result && result.confirmed) {
      addEvent("OTP_CONFIRMED", {}, {}, "interaction");
      successMessage("Celular confirmado!").then(() => {
        // props.nextPage();
        history.push(`/direction?code=${UrlCode}`);
        props.setColorB(2);
      });
    } else {
      infoMessage2("El código es incorrecto");
    }
  }

  //Timer
  useEffect(() => {
    addEvent("STEP_OTP", {}, {}, "interaction");
    try {
      needsOtp();
    } catch (e) {
      commonError("Error enviando OTP!", () => {
        return;
      });
    }

    const time2 = new Date();
    time2.setSeconds(time2.getSeconds() + 300);
    console.log(time2);
    restart(time2.getTime());
  }, []);
  //Needs OTP?
  async function needsOtp() {
    let pro = await props.api.getProject();
    if (pro.hasOtp === false) {
      history.push(`/direction?code=${UrlCode}`);
    } else {
      await props.api.sendOtp();
    }
  }
  useEffect(() => {
    // needsOtp();
  }, []);

  //Resend stuff

  const [mostrarEnviar, setMostrarEnviar] = useState(false);

  async function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  useEffect(() => {
    showEnviar();
  }, []);

  async function showEnviar() {
    await sleep(5000);
    setMostrarEnviar(true);
  }

  async function resend() {
    setMostrarEnviar(false);
    addEvent("OTP_RESEND", {}, {}, "interaction");
    props.api.sendOtp();
    await infoMessage2("SMS reenviado!");
    showEnviar();
    const time2 = new Date();
    time2.setSeconds(time2.getSeconds() + 300);
    console.log(time2);
    restart(time2.getTime());
  }

  return (
    <div className="App instruccion-geo" style={{ color: "black" }}>
      <Container className="recolect_verify_2">
        <Col>
          <img src={smsIcon} alt="icon" />
          {/* {props.needsOtp && (
            <h5 className="text_1">
              CONFIRMA TU
              <br />
              CELULAR
              <br />
            </h5>
          )} */}

          <h5 className="text_1">
            CONFIRMA TU
            <br />
            CELULAR
            <br />
          </h5>

          <p className="text_2">
            Ingresa el codigo de seguridad que te hemos enviado <br />
            a: {props.phone}
            <br />
          </p>
          <div className="div_btns">
            {/* -----------------------OTP_INPUT_CONFIRM_CODE---------------------------------*/}
            <div className="otp-inputs-code">
              <OtpInput
                inputStyle="inputs-code"
                value={opt}
                onChange={handleChange}
                numInputs={5}
                isInputNum={true}
              />
            </div>
            {/* -----------------------TIMER---------------------------------*/}
            <h5 style={{ marginTop: "4%" }}>
              {minutes + ":" + (seconds < 10 ? "0" + seconds : seconds)}
            </h5>

            {/* -----------------------RESEND---------------------------------*/}
            <div hidden={!mostrarEnviar}>
              <Button1
                text="RE-ENVIAR SMS"
                onClick={resend}
                width="70vw"
                height="5vh"
              />
              {/* <p className="text_3" onClick={resend}>
                RE-ENVIAR SMS
              </p> */}
            </div>
            {/* -----------------------BUTTONS---------------------------------*/}
            {props.canEdit && (
              <div style={{ marginTop: "1em" }}>
                <Link to={`/phone?code=${UrlCode}`}>
                  <Button1
                    text="MODIFICAR NÚMERO"
                    onClick={props.setColorB(2)}
                    width="70vw"
                    height="5vh"
                  />
                </Link>
              </div>
              // <Link to={`/phone?code=${UrlCode}`}>
              //   <button
              //     className="btn_number_modify"
              //     onClick={() => {
              //       props.setColorB(2);
              //     }}
              //   >
              //     <h1>MODIFICAR NÚMERO</h1>
              //   </button>
              // </Link>
            )}
            <div style={{ marginTop: "1em" }}>
              <Button
                text="CONFIRMAR NÚMERO"
                onClick={verifyOtpMobile}
                width="70vw"
                height="5vh"
              />
            </div>
            {/* <button className="btn_number_confirm" onClick={verifyOtpMobile}>
              <h1>CONFIRMAR NÚMERO</h1>
            </button> */}
          </div>
        </Col>
      </Container>
    </div>
  );
}
export default RecolectVerify2;
