import React, { useEffect } from "react";
import LoadingOverlay from "react-loading-overlay";
import { Link, useHistory } from "react-router-dom";
import "./DataDoneScreen.scss";
import Loader from "../utils/Loader/Loader";
const secondsToWait = 120;

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

function getTime() {
  var d = new Date();
  return d.getTime();
}

function DataDoneScreen(props) {
  let history = useHistory();
  function gotoClose() {
    history.push("/closeWindow");
  }
  useEffect(() => {
    async function getDataDone() {
      var flag = await props.api.getDataDone();
      var initialTime = null;
      while (!flag) {
        if (!initialTime) initialTime = getTime();
        await sleep(2000);
        flag = await props.api.getDataDone();
        console.log("dataDone:");
        console.log(flag);
        let dif = getTime() - initialTime;
        console.log("time dif: " + dif);
        if (dif > secondsToWait * 1000) {
          flag = true;
        }
      }
      props.api.final(gotoClose);
    }
    getDataDone();
  }, []);
  return (
    <div className="row dataDone" style={{ color: "white" }}>
      <div
        className="col-12 verify"
        style={{
          padding: "1%",
          paddingBottom: "0%",
          marginTop: "10%",
          textAlign: "center",
        }}
      >
        <h2>Estamos terminando tu verificación...</h2>
        <Loader />
        {/* <LoadingOverlay
          active={true}
          // spinner={<BounceLoader />}
          spinner={true}
        />  */}
        {/* <h2>Estamos procesando tu información</h2> */}
        <div style={{ margin: "20px" }}>
          <h2>Por favor espera 1 minuto...</h2>
        </div>
      </div>
    </div>
  );
}
export default DataDoneScreen;
